const FormSubmitted = () => {
  const divStyle = {
    margin: 'auto',
    marginTop: '30px',
    width: '100%',
    // padding: '10px',
    height: '100%',
    textAlign: 'center',
    color: '#262262'
  }

  const footerStyle = {
    position: 'relative',
    left: 0,
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    height: '500px',
    marginTop: '50px',
  }

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'scale-down'
  }

  const imageUrls = [
    'https://uspa-public.s3.us-east-2.amazonaws.com/forms/1711465612476-1a756a_07a444067e0a410bbc97026eae285579~mv2.jpg',
    'https://uspa-public.s3.us-east-2.amazonaws.com/forms/1711465622503-1a756a_30430a15385d464b9846ca67df1b4a61~mv2.jpg',
    'https://uspa-public.s3.us-east-2.amazonaws.com/forms/1711465631287-1a756a_f5aa65cba7c34b89a8789f86d8fd0b62~mv2.jpg',
  ];

  const randomImageUrl = imageUrls[Math.floor(Math.random() * imageUrls.length)];

  const css = `
    html, body {
        min-height: 100%;
        background-color: #f9f9f9;
        }
        body, div, form, input, select, textarea, label, p { 
        padding: 10;
        margin: 10;
        outline: none;
        font-family: Roboto, Arial, sans-serif;
        font-size: 14px;
        // color: #666;
        // line-height: 22px;
        // }
        // h1 {
        // position: relative;
        // margin: 10;
        // font-size: 3vw;
        // color: #262262;
        // text-align: center;
        // }
        // h2 {
        // position: relative;
        // margin: 5;
        // font-size: 25px;
        // color: #262262;
        // text-align: left;
        // }
        .testbox {
          display: block;
          justify-content: center;
          align-items: center;
          height: inherit;
          padding: 20px;
          }
          @media (min-width: 768px) {
              .testboxmedia {
                  margin: 7%;
                  margin-left: 20%;
                  margin-right: 20%;
                  border-radius: 6px;
                  background: #fff;
                  box-shadow: 0 0 8px  #262262; 
                  max-width: 60%;
              }
          }
        }
    `

  return (
    <>
            <style>{css}</style>
            <form class="testbox testboxmedia">
              <div style={divStyle}>
                <h1>Thank you so much!</h1>
                <h2>Your form has been successfully submitted and we appreciate you taking the time to complete it.</h2>
              </div>
              <div style={footerStyle}>
                  <img style={imageStyle} src={randomImageUrl} alt="Branding" />
                </div>
            </form>
        </>
  );
};

export default FormSubmitted;