// import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  // const { t } = useTranslation();

  // return (
  //   <>
  //     <h1>{t('404.sorry')}</h1>
  //     <p>{t('404.notfound')}</p>
  //   </>
  // );

  const divStyle = {
    margin: 'auto',
    marginTop: '100px',
    width: '500px',
    padding: '10px',
    height: '100%',
    'text-align': 'center'
  }

  return (
    <div style={divStyle}>
      <h1>Page Not Found</h1>
      <p> We Are Sorry! We couldn't find the page you are looking for...</p>
    </div>
  );
};

export default NotFoundPage;
