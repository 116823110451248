import NotFoundPage from '../views/404/NotFound';
import {
    PublicPage,
  } from '../enums/pagesUrl';
  import {
    ApApplication,
    FormSubmitted,
    Home, StudentApplication, TeacherRecommendation,
  } from '../views';
  
  export const PUBLIC_ROUTES = {
    [PublicPage.HOME]: <Home />,
    [PublicPage.NOT_FOUND]: <NotFoundPage />,
    [PublicPage.TEACHER_RECOMMENDATION]: <TeacherRecommendation />,
    [PublicPage.AP_APPLICATION]: <ApApplication />,
    [PublicPage.STUDENT_APPLICATION]: <StudentApplication />,
    [PublicPage.FORM_SUBMITTED]: <FormSubmitted />,
    '*': <NotFoundPage />,
  };
  
  