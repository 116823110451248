// import { PageLoader } from '../../components/page';

import { PublicPage } from "./../../enums/pagesUrl";

const Home = () => {

  const divStyle = {
    margin: 'auto',
    marginTop: '100px',
    width: '500px',
    padding: '10px',
    height: '100%',
    'text-align': 'center'
  }
  return (
    <div style={divStyle}>
      <div style={divStyle}>
        <h1>Welcome to USPA Forms Center</h1>
        <h2> The World is Your Campus</h2>
        <p> The Official Online Academic Partner of USA Cycling - USPA</p>
      </div>
      <div style={divStyle}>
        <h2> -- Forms Asset -- </h2>
        {/* <h3> <a href={PublicPage.TEACHER_RECOMMENDATION}>Teacher Recommendation</a> </h3> */}
        <h3> <a href={PublicPage.AP_APPLICATION}>AP Application</a> </h3>
        <h3> <a href={PublicPage.STUDENT_APPLICATION}>Student Application</a> </h3>
      </div>
    </div>
  );
};

export default Home;
