/**
 * When adding or changing URLs, add or modify translations keys and values
 * in Breadcrumbs translations files.
 */

export const PublicPage = {
  HOME : '/',
  NOT_FOUND : '/404',
  TEACHER_RECOMMENDATION: '/teacher-recommendation',
  AP_APPLICATION: '/ap-application',
  STUDENT_APPLICATION: '/student-application',
  FORM_SUBMITTED: '/form-submitted'
}
