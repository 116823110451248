import {
    createBrowserRouter,
    Outlet,
    RouterProvider,
  } from 'react-router-dom';
  
  import { ErrorBoundary } from '../components/error';
  import {
    PublicLayout,
  } from '../components/layouts';

  import {
    PUBLIC_ROUTES,
  } from './routes';

  // IMPORTANT SETTING IN AWS AMPLIFY -> For a complete answer this is my final "Rewrites and redirects" opened in the text editor:
  // https://stackoverflow.com/questions/57449853/react-router-dom-not-working-correctly-on-amplify-console-aws
    // [
    //     {
    //         "source": "https://example.com",
    //         "target": "https://www.example.com",
    //         "status": "302",
    //         "condition": null
    //     },
    //     {
    //         "source": "</^[^.]+$|\\.(?!(css|gif|ico|jpg|js|png|txt|svg|woff|woff2|ttf|map|json|webp|html|xml|webmanifest|mp4)$)([^.]+$)/>",
    //         "target": "/index.html",
    //         "status": "200",
    //         "condition": null
    //     },
    //     {
    //         "source": "/<*>",
    //         "target": "/index.html",
    //         "status": "404-200",
    //         "condition": null
    //     }
    // ]

  
  const Router = () => {
    const router = createBrowserRouter(
      [
        {
          element: <Outlet />,
          errorElement: <ErrorBoundary />,
          children: [
            {
              element: (
                <PublicLayout>
                  <Outlet />
                </PublicLayout>
              ),
              children: Object.entries(PUBLIC_ROUTES).map(([path, element]) => ({
                path,
                element,
              })),
            },
          ],
        },
      ],
      {
        future: {
          v7_normalizeFormMethod: true,
        },
      }
    );
  
    return <RouterProvider router={router} />;
  };
  
  export default Router;
  
