// import { PageLoader } from '../../components/page';
import React, { useState } from 'react';
import { SpinnerCircular } from 'spinners-react';

import { useNavigate} from 'react-router';
import axios from 'axios';
import { PublicPage } from "../../enums/pagesUrl";

const baseURL = 'https://wiz2qe4pyc.execute-api.us-east-1.amazonaws.com/dev';
let isSubmitted = false;

function getHTMLWithFormValues() {
    // Clone the document
    let clone = document.cloneNode(true);

    // Get all input elements from the original document
    let inputs = document.getElementsByTagName('input');
    let textareas = document.getElementsByTagName('textarea');
    let selects = document.getElementsByTagName('select');

    // Get all input elements from the cloned document
    let cloneInputs = clone.getElementsByTagName('input');
    let cloneTextareas = clone.getElementsByTagName('textarea');
    let cloneSelects = clone.getElementsByTagName('select');

    // Get all elements with the class 'testbox' from the cloned document
    var testboxElements = clone.getElementsByClassName('testboxmedia');

    // Remove the style attribute from each 'testboxmedia' element
    for (var i = 0; i < testboxElements.length; i++) {
        testboxElements[i].removeAttribute('style');
    }

    // Update the value attribute of the cloned inputs
    for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].type === 'radio' || inputs[i].type === 'checkbox') {
            // For radio and checkbox inputs, update the checked attribute
            if (inputs[i].checked) {
                cloneInputs[i].setAttribute('checked', 'checked');
            } else {
                cloneInputs[i].removeAttribute('checked');
            }
        } else {
            // For other inputs, update the value attribute
            cloneInputs[i].setAttribute('value', inputs[i].value);
        }
    }

    // Update the value of the cloned textareas
    for (let i = 0; i < textareas.length; i++) {
        cloneTextareas[i].textContent = textareas[i].value;
        cloneTextareas[i].style.height = `${textareas[i].scrollHeight+100}px`;
    }

    // Update the selected option of the cloned selects
    for (let i = 0; i < selects.length; i++) {
        cloneSelects[i].selectedIndex = selects[i].selectedIndex;
        for (let j = 0; j < selects[i].options.length; j++) {
            cloneSelects[i].options[j].selected = selects[i].options[j].selected;
        }
    }

    // // Create a new style element
    // var style = document.createElement('style');

    // // Set the CSS rule for the style element
    // style.textContent = `
    //     @media print {
    //         body {
    //             width: 100%;
    //         }
    //     }
    // `;

    // // Append the style element to the head of the cloned document
    // clone.head.appendChild(style);

      // Select all elements with className="hideOnSubmit" and set their display to "none"
    let hideOnSubmitElements = clone.querySelectorAll('.hideOnSubmit');
    hideOnSubmitElements.forEach(element => {
        element.style.display = 'none';
    });

    // Select all elements with className="displayOnSubmit" and set their display to "block"
    let displayOnSubmitElements = clone.querySelectorAll('.displayOnSubmit');
    displayOnSubmitElements.forEach(element => {
        element.style.display = 'block';
    });

    // Return the outerHTML of the cloned document
    return clone.documentElement.outerHTML;
}

const ApApplication = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    // Initialize an array of selected grades for each select element
    const [selectedGrade, setSelectedGrade] = useState('location');
    const [selectedGradeValue, setSelectedGradeValue] = useState('');

    const handleGradeChange = (event) => {
        setSelectedGrade(selectedGrade => event.target.value);
        setSelectedGradeValue(selectedGradeValue => event.target.options[event.target.selectedIndex].innerHTML);
    };

    const onSubmitEvent = (event) => {
        // console.log('Submit event => id:', {id});
        // var pageHTML = document.documentElement.outerHTML;

        // Prevent the form from submitting
        event.preventDefault();

        // Get the form element
        const form = event.currentTarget.form;

        // Check if the form is valid
        if (form.checkValidity()) {
            console.log('The form is valid');
            
            if(!isSubmitted){
                isSubmitted = true;
                setIsLoading(true);
    
                var pageHTML = getHTMLWithFormValues();
    
                console.log('pageHTML:', pageHTML);
                console.log('pageHTML.length:', pageHTML.length);
                axios.post(baseURL+'/events/post-ap-application', {pageHTML})
                .then((res) => {
                    console.log(res)
                    if(res.status === 200){
                        console.log('Event submitted successfully');
                        navigate(PublicPage.FORM_SUBMITTED, { replace: true });
                    }else{
                        navigate(PublicPage.NOT_FOUND, { replace: true });
                    }
                    setIsLoading(false);
                });
            }   
        } else {
            console.log('The form is not valid');
            // Trigger form validation messages
            form.reportValidity();
        }
    }

    const css = `
    html, body {
        min-height: 100%;
        background-color: #f9f9f9;
        }
        body, div, form, input, select, textarea, label, p { 
        padding: 10;
        margin: 10;
        outline: none;
        font-family: Roboto, Arial, sans-serif;
        font-size: 14px;
        color: #666;
        line-height: 22px;
        }
        h1 {
        position: relative;
        margin: 10;
        font-size: 3vw;
        color: #262262;
        text-align: center;
        }
        h2 {
        position: relative;
        margin: 5;
        font-size: 25px;
        color: #262262;
        text-align: left;
        }
        textarea {
        width: calc(100% - 12px);
        padding: 5px;
        }
        .testbox {
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
        padding: 20px;
        }
        @media (min-width: 768px) {
            .testboxmedia {
                margin: 15%;
                margin-top: 10px;
                border-radius: 6px;
                background: #fff;
                box-shadow: 0 0 8px  #262262; 
            }
        }
        /*form {
        width: 60%;
        padding: 20px;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0 0 8px  #262262; 
        }*/
        .responsive {
        width: 100%;
        height: auto;
        }
        input, select, textarea {
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 3px;
        }
        input {
        width: calc(100% - 10px);
        padding: 5px;
        }
        input[type="date"] {
        padding: 4px 5px;
        }
        textarea {
        width: calc(100% - 12px);
        padding: 5px;
        }
        option {
        background: #fff;
        }
        select, table {
        width: 100%;
        }
        .item:hover p, .item:hover i, .question:hover p, .question label:hover, input:hover::placeholder {
        color:  #262262;
        }
        .item input:hover, .item select:hover, .item textarea:hover {
        border: 1px solid transparent;
        box-shadow: 0 0 3px 0  #262262;
        color: #262262;
        }
        .item {
        position: relative;
        margin: 10px 0;
        }
        .item span {
        color: red;
        }
        .colums {
        display:flex;
        justify-content:space-between;
        flex-direction:row;
        flex-wrap:wrap;
        }
        .colums div {
        width:48%;
        }
        .item i {
        right: 1%;
        top: 30px;
        z-index: 1;
        }
        input[type=radio], input[type=checkbox]  {
        display: block;
        }
        .first-col {
        width: 16%;
        text-align: left;
        }
        .question span {
        margin-left: 30px;
        }
        .question-answer label {
        display: block;
        }
        .flax {
        display:flex;
        justify-content:space-around;
        }
        .btn-block {
        margin-top: 10px;
        text-align: center;
        }
        button {
        width: 150px;
        padding: 10px;
        border: none;
        border-radius: 5px; 
        background:  #262262;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        }
        button:hover {
        background:  #d82a3e;
        transition-duration: 0.3s;
        }
        @media (min-width: 568px) {
        .name-item, .city-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        }
        .name-item input, .name-item div {
        width: calc(50% - 20px);
        }
        .name-item div input {
        width:97%;}
        .name-item div label {
        display:block;
        padding-bottom:5px;
        }
        .checkbox-radio-custom {
          accent-color: #262262;
        }
        }
    `

    return (
        <>
            <style>{css}</style>
            <form class="testbox testboxmedia">
                <div>
                    <div>
                    <img src="https://static.wixstatic.com/media/1a756a_ae478786595a4b4e914a304d7a548b6a~mv2.jpg" alt="USPA Logo The Academic Edge for Elite Athletes" class="responsive"/>
                    </div>
                    <br />
                    <h1> AP Application</h1>
                    <p align="center">Participation in the AP Program is open to any USPA student in grades 11-12 who meets the qualifications listed on the application. Depending on the specific circumstances and long-term Plan of Study, a highly qualified 10th grader could also gain access to the AP Program. A student can apply for two AP classes with one application form – and if accepted into the Program – the maximum number of AP courses that a student can be enrolled in, concurrently, is <u>two</u>. </p>
                    <br />
                    <p align="center">
                    <strong>Instructions: Each of the following sections needs to be completed in its entirety to be considered a complete application.</strong>
                    </p>
                    <br />
                    <br />
                    <div class="colums">
                    <div class="item">
                        <label for="fname">Your First Name <span>*</span>
                        </label>
                        <input id="fname" type="text" name="fname" required />
                    </div>
                    <div class="item">
                        <label for="lname">Your Last Name <span>*</span>
                        </label>
                        <input id="lname" type="text" name="lname" required />
                    </div>
                    <div class="item">
                        <label for="inst">Learning Coach Name <span>*</span>
                        </label>
                        <input id="inst" type="text" name="inst" required />
                    </div>
                    <div class="item">
                        <label for="email">Your USPA Email <span>*</span>
                        </label>
                        <input id="email" type="email" name="email" required />
                    </div>
                    <div className="item">
                        <label htmlFor="grade">Your Current Grade<span>*</span></label>
                        {
                            <select className="hideOnSubmit" value={selectedGrade} onChange={handleGradeChange}>
                                <option className="disabled" value="location" disabled>*Please Select*</option>
                                <option value="1">5th</option>
                                <option value="2">6th</option>
                                <option value="3">7th</option>
                                <option value="4">8th</option>
                                <option value="5">9th</option>
                                <option value="6">10th</option>
                                <option value="7">11th</option>
                                <option value="8">12th</option>
                                <option value="9">PG</option>
                            </select>
                                
                        }
                        <p className="displayOnSubmit" style={{display: "none", border: 'groove', borderWidth: 2}}>{selectedGradeValue}</p>
                    </div>
                    </div>
                    <br/>
                    <hr width="97%" color="#262262" size="3" />
                    <br/>
                    <div class="question-answer">
                    <p align="left"> AP course(s) applying for:</p>
                    <div class="item">
                        <table>
                        <tr>
                            <td>
                            <input class="checkbox-radio-custom" type="checkbox" value="none" name="Govt" />
                            </td>
                            <td>AP United States Government</td>
                        </tr>
                        <tr>
                            <td>
                            <input class="checkbox-radio-custom" type="checkbox" value="none" name="USH" />
                            </td>
                            <td>AP US History</td>
                        </tr>
                        {/* <tr>
                            <td>
                            <input class="checkbox-radio-custom" type="checkbox" value="none" name="Psych" />
                            </td>
                            <td>AP Psychology</td>
                        </tr> */}
                        <tr>
                            <td>
                            <input class="checkbox-radio-custom" type="checkbox" value="none" name="ComputerSciencePrinciples" />
                            </td>
                            <td>AP Computer Science Principles</td>
                        </tr>
                        <tr>
                            <td>
                            <input class="checkbox-radio-custom" type="checkbox" value="none" name="PhysicsI" />
                            </td>
                            <td>AP Physics I</td>
                        </tr>
                        <tr>
                            <td>
                            <input class="checkbox-radio-custom" type="checkbox" value="none" name="CalcAB" />
                            </td>
                            <td>AP Precalculus</td>
                        </tr>
                        <tr>
                            <td>
                            <input class="checkbox-radio-custom" type="checkbox" value="none" name="Stats" />
                            </td>
                            <td>AP Statistics</td>
                        </tr>
                        <tr>
                            <td>
                            <input class="checkbox-radio-custom" type="checkbox" value="none" name="CalcAB" />
                            </td>
                            <td>AP Calculus AB</td>
                        </tr>
                        <tr>
                            <td>
                            <input class="checkbox-radio-custom" type="checkbox" value="none" name="CalcBC" />
                            </td>
                            <td>AP Calculus BC</td>
                        </tr>
                        <tr>
                            <td>
                            <input class="checkbox-radio-custom" type="checkbox" value="none" name="ELIC" />
                            </td>
                            <td>AP English Literature and Composition</td>
                        </tr>
                        <tr>
                            <td>
                            <input class="checkbox-radio-custom" type="checkbox" value="none" name="ELAC" id="ELAC" />
                            </td>
                            <td>AP English Language and Composition</td>
                        </tr>
                        <tr>
                            <td>
                            <input class="checkbox-radio-custom" type="checkbox" value="none" name="Bio" />
                            </td>
                            <td>AP Biology</td>
                        </tr>
                        <tr>
                            <td>
                            <input class="checkbox-radio-custom" type="checkbox" value="none" name="Env" />
                            </td>
                            <td>AP Environmental Science</td>
                        </tr>
                        </table>
                    </div>
                    </div>
                    <br/>
                    <div class="item">
                    <label for="q1">What aspects of this AP course spark your curiosity, and how do you envision this course enriching your academic journey? <span>*</span>
                    </label>
                    <textarea id="q1" rows="3" required></textarea>
                    </div>
                    <br/>
                    <div class="item">
                    <label for="q2">Describe a scenario where the knowledge or skills gained from this AP course would be applicable in your future academic or professional endeavors: <span>*</span>
                    </label>
                    <textarea id="q2" rows="3" required></textarea>
                    </div>
                    <br/>
                    <hr width="97%" color="#262262" size="3" />
                    <br/>
                    <h2> Student Agreement</h2>
                    <p>I will work diligently to make the most of the learning opportunity and maximize the learning of others. I understand that my success will be a direct result of my attendance, effort in class, and the time I invest in coursework.</p>
                    <br />
                    <div class="item">
                    <label for="agreement">If in agreement, please type name below: <span>*</span>
                    </label>
                    <input type="text" name="agreement" id="agreement" required/>
                    </div>
                    <br/>
                    <br/>
                    <hr width="97%" color="#262262" size="3" />
                    <br/>
                    <h2> Student Acknowledgement</h2>
                    <p>It is the responsibility of the student/family to research AP exam dates, locations and fees. It is also the responsibility of the student/family to register (by November) for exam(s) in May. Successful completion of an AP exam is not a requirement for taking a USPA AP course, nor will any subsequent AP exam grade impact a student's final USPA numerical grade in the course.</p>
                    <br />
                    <div class="item">
                    <label for="acknowledge">In order to acknowledge, please type name below: <span>*</span>
                    </label>
                    <input type="text" name="acknowledge" id="acknowledge" required/>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <div class="btn-block">
                        <button type="submit" onClick={onSubmitEvent}>
                            <span
                                style={!isLoading ? {} : {
                                    position: 'relative',
                                    bottom: '7px',
                                    margin: '5px',
                                }}
                            >Submit</span>
                            {isLoading && <SpinnerCircular 
                                size={30}
                                style={{
                                    color: '#d61e3e',
                                    overflow: 'visible',
                                    width: '30px',
                                }}
                                secondaryColor="#ffffff"
                            />}    
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
  }

  export default ApApplication;
  